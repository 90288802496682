import axios from "axios";
import { useContext, useEffect, useState } from "react";
import emailjs from 'emailjs-com';
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import facebooklogo from "../../../assets/icons/facebooklogo.png";
import { useTranslation } from "react-i18next";

interface FormData {
    [key: string]: string | boolean;
}

interface FormErrors {
    [key: string]: boolean;
}

interface Task {
    question: string;
    correctAnswer: string;
    userAnswer: string;
}

export const ContactFormSection = () => {
    const { isContrast } = useContext(AccessibilityContext);
    const [isSent, setIsSent] = useState<boolean>(false);
    const [task, setTask] = useState<Task | null>(null);
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        subject: '',
        message: '',
        consent: false
    });

    const [formErrors, setFormErrors] = useState<FormErrors>({
        name: false,
        email: false,
        subject: false,
        message: false,
        consent: false
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target as HTMLInputElement;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setFormData({ ...formData, [target.name]: value });

        if (target.type === 'checkbox') {
            setFormErrors({ ...formErrors, [target.name]: !target.checked });
        } else {
            setFormErrors({ ...formErrors, [target.name]: target.value.trim() === '' });
        }
    };

    const handleTaskAnswerChange = (value: string) => {
        if (task) {
            setTask({ ...task, userAnswer: value });
        }
    };

    const validateForm = () => {
        const errors: FormErrors = {};
        Object.keys(formData).forEach(key => {
            const value = formData[key];
            if (typeof value === 'string' && value.trim() === '') {
                errors[key] = true;
            } else if (typeof value === 'boolean' && !value) {
                errors[key] = true;
            }
        });

        if (task && task.userAnswer !== task.correctAnswer) {
            errors['task'] = true;
        }
        console.log(errors)
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const generateTasks = (): Task[] => [
        generateAdditionTask(),
        generateSubtractionTask(),
        generateMultiplicationTask(),
    ];

    const selectRandomTask = () => {
        const tasks = generateTasks();
        const randomTask = tasks[Math.floor(Math.random() * tasks.length)];
        setTask(randomTask);
    };

    useEffect(() => {
        selectRandomTask();
    }, []);

    const { t, i18n } = useTranslation();

    const sendEmail = (e: any) => {
        e.preventDefault();
        if (!validateForm()) {
            alert(t("Proszę poprawnie rozwiązać zadanie i wypełnić wszystkie wymagane pola."));
            return;
        }
        setIsSent(true);
        emailjs.send('service_d0w2urj', 'template_64jjx4n', formData, 'CsXyVwhmgAZQ_AJqT')
            .then((result: any) => {
                alert(t("Wiadomość została wysłana!"));
            }, (error: any) => {
                setIsSent(false);
                alert(t("Wystąpił błąd przy wysyłaniu wiadomości."));
            });
    };

    return (
        <section className="md:w-[900px] flex flex-col gap-20" id="kontakt">
            <div className="mx-auto w-full justify-center">
                <h2 className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-40px font-bold leading-[47.41px] text-center pb-[78px] mt-[40px]"}>
                    {t("Chcesz wiedzieć więcej?")}
                </h2>
                <div className={(isContrast ? "bg-cyan-950" : " bg-white") + " md:px-[30px] px-[20px] py-10 rounded-xl justify-between items-start gap-12 flex md:flex-row flex-col "}
                    style={{ boxShadow: '0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)' }}>
                    <div className="md:w-[354px] w-[300px] flex flex-col md:h-[640px]">
                        <span className={(isContrast ? "text-yellow-400" : " text-black") + " md:w-[346px] mb-[20px] text-custom-extended-40px  font-bold leading-[47.41px] md:text-left text-center"}>
                            {t("Zadaj nam pytanie")}
                        </span>
                        <div className="flex flex-col h-full justify-between">
                            <div className="">
                                <span className={(isContrast ? "text-yellow-400" : " text-black") + " text-custom-xl font-normal"}>
                                    {t("Chcesz, aby Twoja placówka była bardziej dostępna dla osób niewidomych i niedowidzących?")} <br />
                                </span>
                                {i18n.language == "pl" ?
                                    <>
                                        <span className={(isContrast ? "text-yellow-400" : " text-black") + " text-custom-xl font-normal"}>
                                            W sekcji
                                        </span>
                                        <a href="#faq" className={(isContrast ? "text-red-600" : "  text-cyan-700") + " text-custom-xl font-normal underline px-1"}>
                                            FAQ
                                        </a>
                                        <span className={(isContrast ? "text-yellow-400" : " text-black") + " text-custom-xl font-normal"}>
                                            nie znalazłeś odpowiedzi na Twoje pytania<br />
                                        </span>
                                    </>
                                    :
                                    <>
                                        <span className={(isContrast ? "text-yellow-400" : " text-black") + " text-custom-xl font-normal"}>
                                            Didn't find the answer to your questions in the
                                        </span>
                                        <a href="#faq" className={(isContrast ? "text-red-600" : "  text-cyan-700") + " text-custom-xl font-normal underline px-1"}>
                                            FAQ
                                        </a>
                                        <span className={(isContrast ? "text-yellow-400" : " text-black") + " text-custom-xl font-normal"}>
                                            section?<br />
                                        </span>
                                    </>
                                }
                                <div className="md:text-left text-center">
                                    <span className={(isContrast ? "text-yellow-400" : " text-black") + " text-custom-xl font-bold"}>
                                        {t("Napisz do nas!")}
                                    </span>
                                </div>
                            </div>
                            <div className="md:flex md:flex-col flex-row md:gap-0 gap-4 hidden">
                                <div className={(isContrast ? "text-yellow-400" : " text-black") + " text-custom-19px font-semibold leading-[27px]"}>
                                    {t("Zobacz jak działamy na: ")}
                                </div>
                                <a href="https://www.facebook.com/profile.php?id=100064351974958" target="_blank">
                                    <img className="w-[40px] pt-[5px]" src={facebooklogo} alt="Logo facebook" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {!isSent ?
                        <form className="w-full flex flex-col md:gap-[23px] gap-[10px]" onSubmit={sendEmail} noValidate>
                            <div className="">
                                <label htmlFor="name" className="sr-only">{t("Imię")} *</label>
                                <input id="name" className={` p-4 w-full h-[55px] bg-white rounded-lg border ${formErrors.name ? 'border-red-500' : 'border-cyan-700 border-opacity-70'}`} type="text" name="name" onChange={handleChange} placeholder={t("Imię") + " *"} required aria-invalid={formErrors.name} aria-describedby="error-name" />
                                {formErrors.name && <span id="error-name" className="text-red-500"> {t("To pole jest wymagane.")}</span>}
                            </div>
                            <div className="">
                                <label htmlFor="email" className="sr-only">{t("Email")} *</label>
                                <input id="email" className={` p-4 w-full h-[55px] bg-white rounded-lg border ${formErrors.email ? 'border-red-500' : 'border-cyan-700 border-opacity-70'}`} type="email" name="email" onChange={handleChange} placeholder={t("Email") + " *"} required aria-invalid={formErrors.email} aria-describedby="error-email" />
                                {formErrors.email && <span id="error-email" className="text-red-500"> {t("To pole jest wymagane.")}</span>}
                            </div>
                            <div className="">
                                <label htmlFor="subject" className="sr-only">{t("Temat")} *</label>
                                <input id="subject" className={` p-4 w-full h-[55px] bg-white rounded-lg border ${formErrors.subject ? 'border-red-500' : 'border-cyan-700 border-opacity-70'}`} type="text" name="subject" onChange={handleChange} placeholder={t("Temat") + " *"} required aria-invalid={formErrors.subject} aria-describedby="error-subject" />
                                {formErrors.subject && <span id="error-subject" className="text-red-500"> {t("To pole jest wymagane.")}</span>}
                            </div>
                            <div className="">
                                <label htmlFor="message" className="sr-only">{t("Wiadomość")} *</label>
                                <textarea id="message" className={` p-4 w-full h-[140px] bg-white rounded-lg border ${formErrors.message ? 'border-red-500' : 'border-cyan-700 border-opacity-70'}`} name="message" onChange={handleChange} placeholder={t("Napisz do nas wiadomość") + " *"} required aria-invalid={formErrors.message} aria-describedby="error-message"></textarea>
                                {formErrors.message && <span id="error-message" className="text-red-500"> {t("To pole jest wymagane.")}</span>}
                            </div>
                            <div className="">
                                <input type="checkbox" id="consent" name="consent" checked={Boolean(formData.consent)} onChange={handleChange} required aria-describedby="error-consent" />
                                <label htmlFor="consent" className={(isContrast ? "text-yellow-400" : "text-black") + " ml-2 text-custom-xl font-normal"}>
                                    {t("Wyrażam zgodę na przetwarzanie moich danych osobowych w celu odpowiedzi na przesłaną wiadomość zgodnie z polityką prywatności.")}
                                </label>
                                <div className="flex flex-col">
                                    {formErrors.consent && <span id="error-consent" className="text-red-500"> {t("To pole jest wymagane.")}</span>}
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                {task && (
                                    <div>
                                        <label>
                                            {t("Rozwiąż zadanie, aby udowodnić, że jesteś człowiekiem")}: {task.question}
                                            <input
                                                type="text"
                                                value={task.userAnswer}
                                                onChange={(e) => handleTaskAnswerChange(e.target.value)}
                                                className={`p-4 w-full h-[55px] bg-white rounded-lg border ${formErrors['task'] ? 'border-red-500' : 'border-cyan-700 border-opacity-70'}`}
                                                required
                                            />
                                        </label>
                                        {formErrors['task'] && <span className="text-red-500">{t("Please solve the task correctly.")}</span>}
                                    </div>
                                )}
                            </div>
                            <button type="submit" className="h-[43px] px-4 py-2.5 bg-cyan-700 rounded-lg justify-center items-center text-white text-custom-xl font-medium cursor-pointer hover:bg-cyan-800">
                                {t("Wyślij")}
                            </button>
                        </form>
                        :
                        <div className=" h-[43px] md:px-[50px] w-full py-2.5 bg-gray-800 rounded-lg justify-center items-center gap-2.5 inline-flex">
                            <div className="text-white text-custom-xl font-medium">{t("Wysłano")}</div>
                        </div>
                    }
                    <div className="flex md:flex-col flex-row md:gap-0 gap-4 md:hidden">
                        <div className={(isContrast ? "text-yellow-400" : " text-black") + " text-custom-19px font-semibold leading-[27px] pt-1"}>
                            {t("Zobacz jak działamy na:")}
                        </div>
                        <a href="https://www.facebook.com/profile.php?id=100064351974958" target="_blank">
                            <img className="w-[40px]" src={facebooklogo} alt="Logo facebook" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

function generateAdditionTask(): Task {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    return {
        question: `${num1} + ${num2} = ?`,
        correctAnswer: (num1 + num2).toString(),
        userAnswer: '',
    };
}

function generateSubtractionTask(): Task {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    return {
        question: `${num1} - ${num2} = ?`,
        correctAnswer: (num1 - num2).toString(),
        userAnswer: '',
    };
}

function generateMultiplicationTask(): Task {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    return {
        question: `${num1} * ${num2} = ?`,
        correctAnswer: (num1 * num2).toString(),
        userAnswer: '',
    };
}