import { useContext, useEffect, useState } from "react";
import { QuestionRow } from "../../../components/QuestionRow";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

interface QuestionData {
    title: string;
    content: string;
}

export const FAQSection = () => {
    const [questions, setQuestions] = useState<QuestionData[]>([]);
    const { t } = useTranslation();
    const { isContrast } = useContext(AccessibilityContext);

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_BE_IP + "getwebpagefaq.php")
            .then((response: any) => {
                let data;
                try {
                    if (typeof response.data === 'string') {
                        data = JSON.parse(response.data);
                    } else {
                        data = response.data;
                    }
                    if (Array.isArray(data)) {
                        setQuestions(data);
                    } else {
                        console.error("Invalid data format: expected an array");
                    }
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    if (questions && questions.length > 0) {
        return <section className={(isContrast ? "bg-black" : " bg-[#C7E1BF]") + " diamond-template flex justify-center"} id="faq">
            <div className="-rotate-[4.12deg] flex flex-col md:gap-20 gap-10 mt-14 md:pb-[100px] pb-[20px]">
                <h2 className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-40px font-bold leading-[47.41px] text-center"}>
                    {t("Często zadawane pytania")}
                </h2>
                <div
                    className={(isContrast ? "bg-black" : " bg-slate-100 ") + " flex flex-col mx-auto px-[50px] md:w-[800px] md:max-w-full max-w-md rounded-[12px] py-10"}
                    style={{ boxShadow: '0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)' }}
                >
                    {questions && questions.map((question: QuestionData, index: number) => {
                        return <QuestionRow key={index} title={question.title} content={question.content} />
                    })}
                </div>
                <div className="flex md:flex-row flex-col md:mx-0 mx-auto md:text-left text-center gap-[20px] justify-center">
                    <span className={(isContrast ? "text-yellow-400" : " text-black ") + " md:pt-2 text-custom-32px font-semibold leading-[37.93px]"}>
                        {t("Masz więcej pytań?")}
                    </span>
                    <button className="md:w-[238.74px]  px-[38.37px] md:py-[14px] bg-cyan-700 hover:bg-cyan-900  rounded-[10.23px] justify-center items-center gap-[12.79px] inline-flex">
                        <a href="#kontakt" className="text-white text-custom-xl font-medium"> {t("Napisz do nas")}</a>
                    </button>
                </div>
            </div>
        </section>
    } else {
        return <section className={(isContrast ? "bg-black" : " bg-[#C7E1BF]") + " diamond-template flex justify-center"} id="faq">
            <div className="-rotate-[4.12deg] flex flex-col md:gap-20 gap-10 mt-14 md:pb-[100px] pb-[20px]">
                <h2 className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-40px font-bold leading-[47.41px] text-center"}>
                    {t("Często zadawane pytania")}
                </h2>
                <div
                    className={(isContrast ? "bg-black" : " bg-slate-100 ") + " flex flex-col mx-auto px-[50px] md:w-[800px] md:max-w-full max-w-md rounded-[12px] py-10"}
                    style={{ boxShadow: '0px 0px 24.399999618530273px 6px rgba(0, 0, 0, 0.10)' }}
                >
                    <span>FAQ jest chwilowo niedostępne. Prosimy spróbować ponownie później... </span>
                </div>
                <div className="flex md:flex-row flex-col md:mx-0 mx-auto md:text-left text-center gap-[20px] justify-center">
                    <span className={(isContrast ? "text-yellow-400" : " text-black ") + " md:pt-2 text-custom-32px font-semibold leading-[37.93px]"}>
                        {t("Masz więcej pytań?")}
                    </span>
                    <button className="md:w-[238.74px]  px-[38.37px] md:py-[14px] bg-cyan-700 hover:bg-cyan-900  rounded-[10.23px] justify-center items-center gap-[12.79px] inline-flex">
                        <a href="#kontakt" className="text-white text-custom-xl font-medium"> {t("Napisz do nas")}</a>
                    </button>
                </div>
            </div>
        </section>
    }
}