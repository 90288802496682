import { useContext, useEffect, useState } from "react";
import { StatsRow } from "./StatsRow";
import React from "react";
import { StatsDetailRow } from "./StatsDetailRow";
import { AccessibilityContext } from "../../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";


export interface IStatsDetail {
    detailInfo: string;
    length: number
}

export interface IStatsRow {
    id: number;
    title: string;
    length: number;
    details: IStatsDetail[];
}



export const StatsContener = () => {
    const [selectedStatsId, setSelectedStatsId] = useState<number>(1);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const { t } = useTranslation();
    const statsDetail: IStatsRow[] = [
        { id: 1, title: "Jednostek samorządu terytorialnego", length: 124, details: [{ detailInfo: "Urzędów Miasta", length: 32 }, { detailInfo: "Urzędów Gminy", length: 29 }, { detailInfo: "Starostw Powiatowych", length: 18 }, { detailInfo: "Sądów", length: 17 }, { detailInfo: "Inne", length: 28 }] },
        { id: 2, title: "Ośrodków kulturalnych", length: 59, details: [{ detailInfo: "Teatrów", length: 19 }, { detailInfo: "Muzeów", length: 20 }, { detailInfo: "Ośrodków kultury", length: 19 }, { detailInfo: "Kino", length: 1 }] },
        { id: 3, title: "Uczelni wyższych i placówek edukacyjnych", length: 41, details: [{ detailInfo: "Akademii ", length: 8 }, { detailInfo: "Uniwersytetów", length: 10 }, { detailInfo: "Politechniki", length: 3 }, { detailInfo: "Szkół wyższych", length: 5 }, { detailInfo: "SP+LO", length: 15 }] },
        { id: 4, title: "Placówek ZUS", length: 48, details: [{ detailInfo: "Oddziałów", length: 8 }, { detailInfo: "Inspektoratów", length: 33 }, { detailInfo: "Biur terenowych", length: 7 }] }
    ]

    useEffect(() => {
        const handleResize = () => {

            if (window.innerWidth <= 767) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const { isContrast } = useContext(AccessibilityContext);
    return (
        <div className="w-full flex flex-col items-center justify-center gap-10">
            <div className="w-full grid grid-cols-2 gap-8 md:flex md:flex-row md:justify-center md:items-center relative">
                {statsDetail.map((stats, index: number) => (
                    <div key={stats.id} className={`flex flex-col justify-around items-center`}>
                        <StatsRow
                            stats={stats}
                            isSelected={selectedStatsId === stats.id}
                            onClick={() => setSelectedStatsId(stats.id)}
                            tabIndex={0}
                        />
                        {!isMobile ?
                            selectedStatsId == stats.id && (
                                <div className="absolute mt-[195px]">
                                    <div className="absolute w-[2px] h-[39.5px] bg-gray-400" />
                                    <svg
                                        className={`absolute stroke-current w-[20px] h-[20px] mt-[26px] -left-[9px] text-gray-400`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24.9 13.3"
                                        fill="none"
                                        stroke={""}
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <polyline points=".35 .64 12.31 12.59 24.55 .35" />
                                    </svg>
                                </div>
                            )
                            :
                            selectedStatsId == stats.id && (
                                selectedStatsId == 1 ?
                                    <div className="absolute">
                                        <div className="absolute w-[18px] h-[2px] ml-[74px] bg-gray-400" />
                                        <div className="absolute w-[2px] h-[309.5px] ml-[90px] bg-gray-400" />
                                        <svg
                                            className={`absolute stroke-current w-[20px] h-[32px] mt-[290px] left-[81px] text-gray-400`}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24.9 13.3"
                                            fill="none"
                                            stroke={""}
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            <polyline points=".35 .64 12.31 12.59 24.55 .35" />
                                        </svg>
                                    </div>
                                    : selectedStatsId == 2 ?
                                        <div className="absolute">
                                            <div className="absolute w-[18px] h-[2px] -ml-[92px] bg-gray-400" />
                                            <div className="absolute w-[2px] h-[309.5px] -ml-[92px] bg-gray-400" />
                                            <svg
                                                className={`absolute stroke-current w-[20px] h-[32px] mt-[290px] right-[81px] text-gray-400`}
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24.9 13.3"
                                                fill="none"
                                                stroke={""}
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <polyline points=".35 .64 12.31 12.59 24.55 .35" />
                                            </svg>
                                        </div>
                                        : selectedStatsId == 3 ?
                                            <div className="absolute">
                                                <div className="absolute w-[18px] h-[2px] ml-[74px] bg-gray-400" />
                                                <div className="absolute w-[2px] h-[117px] ml-[90px] bg-gray-400" />
                                                <svg
                                                    className={`absolute stroke-current w-[20px] h-[20px] mt-[104px] left-[81px] text-gray-400`}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24.9 13.3"
                                                    fill="none"
                                                    stroke={""}
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    <polyline points=".35 .64 12.31 12.59 24.55 .35" />
                                                </svg>
                                            </div>
                                            : selectedStatsId == 4 ?
                                                <div className="absolute">
                                                    <div className="absolute w-[18px]  h-[2px] -ml-[92px] bg-gray-400" />
                                                    <div className="absolute w-[2px] h-[117px] -ml-[92px] bg-gray-400" />
                                                    <svg
                                                        className={`absolute stroke-current w-[20px] h-[20px] mt-[104px] right-[81px] text-gray-400`}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24.9 13.3"
                                                        fill="none"
                                                        stroke={""}
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        <polyline points=".35 .64 12.31 12.59 24.55 .35" />
                                                    </svg>
                                                </div>
                                                : <></>
                            )
                        }
                        {selectedStatsId === stats.id && (
                            <div className="absolute md:left-[50px] md:top-[240px] left-0 top-[392px] w-full h-[150px]">
                                <StatsDetailRow details={stats.details} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div>
            </div>
        </div>
    );
}

