// Header.tsx
import React, { useContext, useEffect, useState } from 'react';
import { HamburgerIconComponent } from '../assets/common/HamburgerIconComponent';
import { Link, useLocation, useNavigate, useResolvedPath, useRoutes } from 'react-router-dom';
import { AccessibilityContext } from '../contexts/AccessibilityContext';
import logo from "../assets/common/logo.png";
import text_normal from "../assets/accessibilityIcons/text_normal.png";
import text_large from "../assets/accessibilityIcons/text_large.png";
import text_largest from "../assets/accessibilityIcons/text_largest.png";
import contrast from "../assets/accessibilityIcons/contrsat_black.png";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

interface HeaderProps {
    setIsOpen: (isOpen: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ setIsOpen }) => {
    const { setActiveLink, setIsContrast, changeSizeText, sizeText, isContrast } = useContext(AccessibilityContext);

    const navigate = useNavigate();
    const handleNavigate = (sectionId: string) => {
        setActiveLink("");
        navigate('/');
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    };
    const { t } = useTranslation();

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    const isActive = (language: string) => {
        return i18n.language === language;
    };

    const location = useLocation();

    const isHomePage = location.pathname === '/';


    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    const toggleLanguage = () => {
        const newLanguage = currentLanguage === 'pl' ? 'en' : 'pl';
        i18n.changeLanguage(newLanguage);
        setCurrentLanguage(newLanguage);
    };


    return (
        <header className={" flex flex-col z-10"}>
            <div className="flex flex-col">
                <div className="flex flex-row md:h-full h-[60px]">
                    <div className="flex-none md:mt-4 mt-3">
                        {isHomePage
                            ?
                            <img
                                className="md:w-[248px] md:h-[92px] w-[100px] md:pl-0 pl-2 md:pt-0 pt-3 object-contain"
                                alt={t("Strona główna - Pirs logo")}
                                src={logo}
                            />
                            :
                            <Link to={'/'} tabIndex={0}>
                                <img
                                    className="md:w-[248px] md:h-[92px] w-[100px] md:pl-0 pl-2 md:pt-0 pt-3 object-contain"
                                    alt={t("Wróć na stronę główną - Pirs logo")}
                                    src={logo}
                                />
                            </Link>
                        }
                    </div>
                    <div className="flex-grow flex flex-col justify-between">
                        <div className="flex justify-between md:space-x-4 md:mt-[40px]">
                            <div className="md:ml-3 md:mt-0 mt-[21px] md:mr-5">
                                <div className='flex flex-row gap-5 ml-[20px] text-center md:ml-4 md:mt-[7px] mt-[3px]'>
                                    <div className='flex flex-col mb-[7px]'>
                                        <div className='flex flex-row gap-1 justify-end text-[15px] font-[400]'>
                                            {isActive('pl') ? (
                                                <span className={`font-[900] px-1 py-1.5 ${isContrast ? 'text-yellow-400' : 'text-sky-950'}`} aria-label={t('Polska wersja aktywna')}>pl</span>
                                            ) : (
                                                <button aria-label={t('Zmień na język Polski')} className={`px-1 py-1 ${isContrast ? 'text-white' : 'text-black'}`} onClick={() => changeLanguage("pl")}>pl</button>
                                            )}
                                            <div className={`py-1 font-black text-[18px] ${isContrast ? 'text-white' : 'text-black'}`}>|</div>
                                            {isActive('en') ? (
                                                <span className={`font-[900] px-1 py-1.5 ${isContrast ? 'text-yellow-400' : 'text-sky-950'}`} aria-label={t('Angielska wersja aktywna')}>en</span>
                                            ) : (
                                                <button aria-label={t('Zmień na język Angielski')} className={`px-1 py-1 ${isContrast ? 'text-white' : 'text-black'}`} onClick={() => changeLanguage("en")}>en</button>
                                            )}
                                        </div>
                                    </div>
                                    <div className='mt-[5px]'>
                                        <button
                                            tabIndex={0}
                                            onClick={() => { setIsContrast(!isContrast); }}
                                            onKeyDown={(e) => { if (e.key === 'Enter') setIsContrast(!isContrast); }}
                                            className='cursor-pointer'
                                            aria-label={t('Zmień kontrast')}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 134.42 134.42"
                                                className={`w-6 h-6`}
                                            >
                                                <defs>
                                                    <style>
                                                        {`.cls-1{fill:${isContrast ? "rgb(250 204 21)" : "black"};stroke:${isContrast ? "rgb(250 204 21)" : "black"};stroke-miterlimit:10;stroke-width:4px;}`}
                                                        {`.cls-2{fill:none;stroke-width:0px;}`}
                                                    </style>
                                                </defs>
                                                <g>
                                                    <rect className="cls-2" width="134.42" height="134.42" />
                                                    <path
                                                        className="cls-1"
                                                        d="M67.21,22.25c-24.83,0-44.96,20.13-44.96,44.96s20.13,44.96,44.96,44.96,44.96-20.13,44.96-44.96-20.13-44.96-44.96-44.96ZM67.21,110.43V23.99c23.87,0,43.22,19.35,43.22,43.22s-19.35,43.22-43.22,43.22Z"
                                                    />
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={(isContrast ? "text-yellow-400 " : "text-[#12728C] ") + "hidden md:flex gap-6 font-bold text-custom-16px mt-[3px]"}>
                                <a href="#faq" className='pt-[9px]' onClick={(e) => { e.preventDefault(); handleNavigate('faq'); }}>FAQ</a>
                                <a href="#galeria" className="pt-[9px]" onClick={(e) => { e.preventDefault(); handleNavigate('galeria'); }}>{t("Galeria")}</a>
                                <a href="#gdziejestesmy" className='leading-[18px] pt-[6px]' aria-label={'TOTUPOINT' + t('w liczbach')} onClick={(e) => { e.preventDefault(); handleNavigate('gdziejestesmy'); }}>TOTUPOINT <br />{t("w liczbach")}</a>

                            </div>
                            <div className="flex flex-row md:gap-10 gap-2 md:mt-[9px] mt-[25px] h-[35px]">
                                <Link to={"lokalizacje"} className={" md:w-[130px] w-[120px] bg-cyan-700 rounded-lg text-white hover:bg-cyan-950 text-[15px] md:pt-0 text-center"}>
                                    <div className='mt-1.5'>
                                        {t("Lokalizacje")}
                                    </div>
                                </Link>
                            </div>
                            <div className='md:mt-[0px] mt-[15px]'>
                                <HamburgerIconComponent setIsOpen={setIsOpen} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:mt-5 mt-4 md:mx-0 mx-2">
                    <div className={(isContrast ? "border-yellow-400" : "border-black") + " border-t"}></div>
                </div>
            </div>
        </header >
    );
};

export default Header;