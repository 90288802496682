import { BrowserRouter, HashRouter, Route, Router, Routes, useLocation } from "react-router-dom";
import { Main } from "./pages/Main/Main";
import { MapGoogle } from "./components/MapGoogle";
import Sidebar from "./layouts/Sidebar";
import Header from "./layouts/Header";
import { Footer } from "./layouts/Footer";
import { useContext, useEffect, useRef, useState } from "react";
import { Localization } from "./pages/Localization/Localization";
import { LocationDetails } from "./pages/Localization/LocationDetail/LocationDetail";
import { LocationContext, LocationProvider } from "./contexts/LocationContext";
import { ContentPage } from "./pages/ContentPage/ContentPage";
import { AccessibilityContext, AccessibilityProvider } from "./contexts/AccessibilityContext";
import { LocationDetailsContent } from "./pages/Localization/LocationDetail/LocationDetailsContent/LocationDetailsContent";
import "./App.css"

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isContrast } = useContext(AccessibilityContext);
  const closeSidebar = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isOpen) {
      setIsOpen(false);
      e.stopPropagation();
    }
  };
  const location = useLocation();
  // const mainContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "Strona główna - TOTUPOINT";
        break;
      case "/lokalizacje":
        document.title = "Lokalizacje - TOTUPOINT";
        break;
      case location.pathname.match(/\/lokalizacje\/\d+/)?.input:
        document.title = "Szczegóły lokalizacji - TOTUPOINT";
        break;
    }
  }, [location]);

  // useEffect(() => {
  //   if (mainContentRef.current) {
  //     const firstFocusableElement = mainContentRef.current.querySelector<HTMLElement>('h1');
  //     if (firstFocusableElement) {
  //       firstFocusableElement.focus();
  //     }
  //   }
  // }, [location.pathname]);

  return (
    <div className="font-roboto">
      {isOpen && <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />}
      <div className={`${isOpen ? "opacity-20 bg-[#D9D9D9]" : ""} ${isContrast ? "bg-black" : "bg-gray-50"} min-h-screen overflow-x-hidden`}
        onClick={closeSidebar}>
        <LocationProvider>
          <div className="scaleContainer">
            <div className="md:max-w-[900px] max-w-md w-full mx-auto">
              <Header setIsOpen={setIsOpen} />
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/lokalizacje" element={<Localization />} />
                <Route path="/lokalizacje/:id" element={<LocationDetails />} />
                <Route path="/lokalizacje/:id/:name" element={<LocationDetailsContent />} />
                <Route path="/:name" element={<ContentPage />} />
              </Routes>
              <Footer />
            </div>
          </div>
        </LocationProvider>
      </div>
    </div >
  );
}

export default App;