import { useContext, useEffect, useRef, useState } from "react";
import { LocationDetail } from "../models/Location";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AccessibilityContext } from "../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../contexts/LocationContext";

interface IFilteredLocation {
    filteredLocations: LocationDetail[];
}

interface LocationGroup {
    total: number;
    locations: Record<string, LocationDetail[]>;
}

interface GroupedResults {
    [city: string]: LocationGroup;
}

export const FilteredLocation: React.FC<IFilteredLocation> = ({ filteredLocations }) => {
    const { expandedCity, setExpandedCity, expandedLocation, setExpandedLocation } = useContext(LocationContext);
    const { isContrast } = useContext(AccessibilityContext);

    const expandedLocationRef = useRef<HTMLButtonElement | null>(null);
    const expandedCityRef = useRef<HTMLButtonElement | null>(null);
    const topPageRef = useRef<HTMLDivElement | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const paramCity = searchParams.get("expandedCity");
        const paramLocation = searchParams.get("expandedLocation");

        if (paramCity) {
            setExpandedCity(paramCity);
        } else {
            setExpandedCity(null)
        }
        if (paramLocation) {
            setExpandedLocation(paramLocation);
        } else {
            setExpandedLocation(null)
        }
    }, [searchParams, setExpandedCity, setExpandedLocation]);

    useEffect(() => {
        if (expandedLocationRef.current) {
            expandedLocationRef.current.focus();
        } else if (expandedCityRef.current) {
            expandedCityRef.current.focus();
        } else if (topPageRef.current) {
            topPageRef.current.focus();
        }
    }, [expandedCity, expandedLocation, filteredLocations]);

    const handleCityClick = (city: string) => {
        const newCity = expandedCity === city ? null : city;
        setExpandedCity(newCity);
        const params = new URLSearchParams(searchParams);
        if (newCity) {
            params.set("expandedCity", newCity);
        } else {
            params.delete("expandedCity");
        }
        params.delete("expandedLocation");
        setSearchParams(params);
    };

    const handleLocationClick = (locationKey: string) => {
        const newLocation = expandedLocation === locationKey ? null : locationKey;
        setExpandedLocation(newLocation);
        const params = new URLSearchParams(searchParams);
        if (newLocation) {
            params.set("expandedLocation", newLocation);
        } else {
            params.delete("expandedLocation");
        }
        setSearchParams(params);
    };

    const groupedResults = (): GroupedResults => {
        const unsortedResults = filteredLocations.reduce((acc: GroupedResults, loc: LocationDetail) => {
            const streetNumber = String(loc.number).split("#")[0];
            const streetFullName = `${loc.street} ${streetNumber}`;
            const locationKey = `${loc.name}, ${streetFullName}`;
            if (!acc[loc.city]) {
                acc[loc.city] = { total: 0, locations: {} };
            }
            if (!acc[loc.city].locations[locationKey]) {
                acc[loc.city].locations[locationKey] = [];
            }

            acc[loc.city].locations[locationKey].push(loc);
            acc[loc.city].total += 1;
            return acc;
        }, {});

        const sortedCities = Object.entries(unsortedResults).sort(([cityA], [cityB]) => cityA.localeCompare(cityB));

        const sortedResults = sortedCities.map(([city, { total, locations }]) => {
            const sortedLocations = Object.entries(locations).sort(([locationA], [locationB]) => locationA.localeCompare(locationB));
            const locationsSortedObj = sortedLocations.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
            return { city, total, locations: locationsSortedObj };
        });

        const finalStructure = sortedResults.reduce((acc, { city, total, locations }) => {
            acc[city] = { total, locations };
            return acc;
        }, {} as GroupedResults);
        return finalStructure;
    };

    const removeTrailingComma = (str: string): string => {
        var trimmed = str.trim();
        return trimmed.endsWith(",") ? trimmed.slice(0, -1) : str;
    };

    const { t } = useTranslation();

    const renderList = () => {
        const result = groupedResults();
        return Object.entries(result).map(([city, { total, locations }]) => (
            <div key={city} className="ml-[0px]">
                <button
                    ref={expandedCity === city ? expandedCityRef : null}
                    tabIndex={0}
                    className={(isContrast ? "text-yellow-400" : " text-cyan-700 ") + " text-custom-xl font-normal font-['Roboto'] leading-normal my-[9px]"}
                    onClick={() => handleCityClick(city)}
                    aria-expanded={expandedCity === city}
                    aria-controls={`locations-${city}`}
                    aria-label={city + ` (${total})`}
                >
                    <span className="cursor-pointer">{city + ` (${total})`}</span>
                </button>
                <div className="w-full h-[0.5px] bg-zinc-300" />
                {expandedCity === city && (
                    <div className="md:ml-[20px] ml-[5px]" id={`locations-${city}`}>
                        {Object.entries(locations).map(([locationKey, locationDetails]) => (
                            <div key={locationKey}>
                                <div className="flex flex-row text-cyan-700 gap-1">
                                    <span className="md:my-auto md:text-[30px] text-[15px] ">-</span>
                                    <button
                                        ref={expandedLocation === locationKey ? expandedLocationRef : null}
                                        tabIndex={0}
                                        className={(isContrast ? "text-yellow-400" : " text-cyan-700 ") + " text-custom-xl font-normal font-['Roboto'] leading-normal cursor-pointer text-left"}
                                        onClick={() => handleLocationClick(locationKey)}
                                        aria-expanded={expandedLocation === locationKey}
                                        aria-controls={`details-${locationKey}`}
                                        aria-label={`${removeTrailingComma(locationKey)} (${locationDetails.length})`}
                                    >
                                        {removeTrailingComma(locationKey)} ({locationDetails.length})
                                    </button>
                                </div>
                                <div className="w-full h-[0.5px] bg-zinc-300" />
                                {expandedLocation === locationKey && (
                                    <ul className={(isContrast ? "text-yellow-400" : "text-teal-800") + " ml-[40px] mt-[42] text-custom-16px font-normal font-['Roboto'] leading-normal list-decimal"} id={`${locationKey}`}>
                                        {locationDetails
                                            .map(detail => ({
                                                ...detail,
                                                sortSubnameKey: `${detail.sort}${detail.subname}`
                                            }))
                                            .sort((a, b) => a.sortSubnameKey.localeCompare(b.sortSubnameKey))
                                            .map((detail, index) => (
                                                <li className="md:ml-[40px] ml-[10px] mt-[4px]" key={index}>
                                                    <Link to={`/lokalizacje/${detail.id}`} aria-label={`${detail.subname}`}>{detail.subname}</Link>
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div >
        ));
    };

    return (
        <div>
            {renderList()}
        </div>
    );
}