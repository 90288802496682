import { useContext, useEffect, useRef, useState } from "react";
import { LocationDetail } from "../../../models/Location";
import sound from "../../../assets/icons/sound.png"
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
interface AudioPlayerProps {
    location: LocationDetail;
    tonePathUrl: string;
}


export const Audio: React.FC<AudioPlayerProps> = ({ location, tonePathUrl }) => {
    const audioToneBefRef = useRef<HTMLAudioElement>(null);
    const audioToneAftRef = useRef<HTMLAudioElement>(null);
    const audioVoiceRef = useRef<HTMLAudioElement>(null);
    const [tonePath, setTonePath] = useState<string>(tonePathUrl);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const { isContrast } = useContext(AccessibilityContext);
    const [isToneBefExist, setIsToneBefExist] = useState<boolean>(false);
    const [isToneAftExist, setIsToneAftExist] = useState<boolean>(false);
    const [isVoiceExist, setIsVoiceExist] = useState<boolean>(false);

    const checkFileExistence = async (url: string): Promise<boolean> => {
        try {
            await axios.get(url);
            return true;
        } catch {
            return false;
        }
    };

    useEffect(() => {
        const checkFiles = async () => {

            const toneAftFile = location.toneaft ? await checkFileExistence(process.env.REACT_APP_SERVER_BE_IP + "zarzadzanie/wav/" + location?.toneaft?.file) : false;
            const toneBefFile = location.tonebef ? await checkFileExistence(process.env.REACT_APP_SERVER_BE_IP + "zarzadzanie/wav/" + location?.tonebef?.file) : false;
            const voiceFile = location.voicefile ? await checkFileExistence(process.env.REACT_APP_SERVER_BE_IP + new URL("http://" + location.voicefile).pathname) : false;

            setIsToneBefExist(toneBefFile);
            setIsToneAftExist(toneAftFile);
            setIsVoiceExist(voiceFile);
        };

        checkFiles();
    }, []);


    const startPlay = () => {
        if (audioToneBefRef.current && audioVoiceRef.current && audioToneAftRef.current && location.tonebef && location.toneaft && isToneBefExist && isToneAftExist && isVoiceExist) {
            setIsButtonDisabled(true);
            const toneBaseAftDuration = audioToneAftRef.current.duration * 1000 + 200;
            const toneBaseBefDuration = audioToneBefRef.current.duration * 1000 + 200;
            const toneBefTotalDuration = toneBaseBefDuration * location.tonebef.count;
            const toneAftTotalDuration = toneBaseAftDuration * location.toneaft.count;
            const voiceDelay = toneBefTotalDuration + 100;
            const toneReplayDelay = voiceDelay + audioVoiceRef.current.duration * 1000 + 200;

            for (let i = 0; i < location.tonebef.count; i++) {
                const delay = toneBaseBefDuration * i;
                setTimeout(() => audioToneBefRef.current?.play(), delay);
            }

            setTimeout(() => audioVoiceRef.current?.play(), voiceDelay);
            for (let i = 0; i < location.toneaft.count; i++) {
                const delay = toneReplayDelay + toneBaseAftDuration * i;
                setTimeout(() => audioToneAftRef.current?.play(), delay);
            }

            setTimeout(() => setIsButtonDisabled(false), (toneAftTotalDuration + toneReplayDelay))

        } else if ((isToneBefExist || isToneAftExist) && !isVoiceExist && location.toneaft && audioToneBefRef.current) {

            for (let i = 0; i < location.toneaft.count; i++) {
                const delay = audioToneBefRef.current.duration * i;
                setTimeout(() => audioToneBefRef.current?.play(), delay);
            }
        } else if ((!isToneAftExist || !isToneBefExist) && isVoiceExist && audioVoiceRef.current) {
            setTimeout(() => audioVoiceRef.current?.play(), 200);
        }
    };
    useEffect(() => {
        setTonePath("api/zarzadzanie/wav/");
    }, [tonePath])
    const { t } = useTranslation();

    const getButtonText = () => {
        if (isToneAftExist && isToneBefExist && isVoiceExist) {
            return t("Posłuchaj dźwięku znacznika");
        } else if ((!isToneAftExist || !isToneBefExist) && isVoiceExist) {
            return t("Posłuchaj komunikatu głosowego");
        } else if (isToneAftExist && isToneBefExist && !isVoiceExist) {
            return t("Posłuchaj sygnałów tonowych znacznika");
        } else {
            return t("Brak dźwięku dla znacznika");
        }
    };


    const buttonText = getButtonText();
    const showButton = !((!isToneAftExist || !isToneBefExist) && !isVoiceExist);

    return (
        showButton ?
            <button
                onClick={startPlay}
                className="py-[8.51px] px-3 bg-cyan-700 rounded-[6.81px] justify-center items-center gap-[8.51px] inline-flex hover:bg-cyan-900"
                disabled={isButtonDisabled}
            >
                <img className="w-7 h-7" src={sound} alt={t("Ikona głośnika")} />
                <div className="text-white text-custom-17px font-medium font-['Roboto']">{buttonText}</div>
                {isToneBefExist && <audio ref={audioToneBefRef} src={`/api/zarzadzanie/wav/${location?.tonebef?.file}`} onError={() => console.error('Błąd podczas ładowania pliku audio 1')} />}
                {isToneAftExist && <audio ref={audioToneAftRef} src={`/api/zarzadzanie/wav/${location?.toneaft?.file}`} onError={() => console.error('Błąd podczas ładowania pliku audio 2')} />}
                {/* {isVoiceExist && <audio ref={audioVoiceRef} src={`http://${location.voicefile}`} onError={() => console.error('Błąd podczas ładowania pliku audio 3')} />} */}
                {isVoiceExist && <audio ref={audioVoiceRef} src={"/api" + new URL("http://" + location.voicefile).pathname} onError={() => console.error('Błąd podczas ładowania pliku audio 3')} />}
            </button>
            :
            <div className={isContrast ? "text-white" : "text-black"}>{buttonText}</div>
    );
}