import { useEffect, useState } from "react";
import { MapGoogle } from "../../components/MapGoogle";
import "./Main.css";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import { Footer } from "../../layouts/Footer";
import { HeaderSection } from "./sections/HeaderSection";
import { FeatureSection } from "./sections/FeatureSection";
import { AccessibilitySection } from "./sections/AccessibilitySection";
import { HowItWorksSection } from "./sections/HowItWorksSection";
import { LocationSection } from "./sections/LocationSection";
import { GallerySection } from "./sections/GallerySection";
import { FAQSection } from "./sections/FAQSection";
import { StatisticsSection } from "./sections/StatisticsSection";
import { ContactFormSection } from "./sections/ContactFormSection";
import { SecuritySection } from "./sections/SecuritySection";
import { FacebookResponse } from "../../models/Facebook";
import { FacebookPostsSection } from "./sections/FacebookPostsSection";

export const Main = () => {
    const [isOpen, setIsOpen] = useState(false);


    return (
        <main role="main" className="flex flex-col md:mt-14 mt-10 md:mb-[203px] mb-[100px] md:mx-0 mx-5 max-w-md md:max-w-max">
            <div className="flex-col">
                <HeaderSection />
                <FeatureSection />
            </div>
            <StatisticsSection />
            <SecuritySection />
            <HowItWorksSection />
            <AccessibilitySection />
            <FacebookPostsSection />
            <FAQSection />
            <GallerySection />
            <ContactFormSection />
        </main>

    );
}