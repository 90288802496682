import { useContext, useEffect, useState } from "react";
import { AccessibilityContext } from "../contexts/AccessibilityContext";
import arrowDown_black from "../assets/common/arrowDown_black.png"
import arrowDown_yellow from "../assets/common/arrowDown_yellow.png"
import arrowBack_black from "../assets/common/arrowBack_black.png"
import arrowBack_yellow from "../assets/common/arrowBack_yellow.png"
import { useTranslation } from "react-i18next";

interface IQuestionRow {
    title: string;
    content: string;
}
export const QuestionRow: React.FC<IQuestionRow> = ({ title, content }) => {
    const { isContrast } = useContext(AccessibilityContext);
    const [isOpen, setIsOpen] = useState(false);
    const [titleRow, setTitleRow] = useState<string>(title);
    const [contentRow, setContentRow] = useState<string>(content);
    const toggleOpen = () => setIsOpen(!isOpen);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setTitleRow(t(title))
    }, [i18n.language]);

    return (
        <div className="w-full">
            <div className="flex flex-row justify-between items-center py-[7px] md:pl-[10px] pl-[3px] cursor-pointer" onClick={toggleOpen}>
                <div className={(isContrast ? "text-yellow-400" : " text-black ") + " md:w-full w-[300px] text-custom-16px font-medium leading-7"}>{titleRow}</div>
                <button
                    onKeyDown={(e) => { if (e.key === 'Enter') toggleOpen() }}
                    tabIndex={0}
                    className="w-6 h-6 relative mr-3 ml-3"
                    aria-label={t("Rozwiń odpowiedź na pytanie")}
                >
                    {!isOpen ?
                        (
                            <svg
                                className={`stroke-current mt-2`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24.9 13.3"
                                fill="none"
                                stroke={""}
                                strokeWidth="1"
                                color={!isContrast ? "black" : 'rgb(230 204 21)'}
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <polyline points=".35 .64 12.31 12.59 24.55 .35" />
                            </svg>
                        )
                        :
                        (
                            <svg
                                className={`stroke-current mt-2 ml-1`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 13.3 24.9"
                                fill="none"
                                height={23}
                                stroke={""}
                                strokeWidth="1"
                                color={!isContrast ? "black" : 'rgb(230 204 21)'}
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <polyline points="12.66 .35 .71 12.31 12.95 24.55" />
                            </svg>
                        )
                    }
                </button>
            </div>
            {isOpen && (
                <div className={(isContrast ? "text-yellow-400" : " text-black ") + " w-full md:px-[10px] px-[5px] pt-[5px] pb-[15px] text-custom-14px"}>
                    <p className=" pr-[40px]">{t(content)}</p>
                </div>
            )}
            <div className={(isContrast ? "border-yellow-400" : "  border-black") + "  w-full h-[1px] border text-right"}></div>
        </div>
    );
};