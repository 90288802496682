import { GoogleMap, InfoWindow, Libraries, Marker, MarkerClusterer, useLoadScript } from "@react-google-maps/api";
import { useContext, useEffect, useRef, useState } from "react";
import { LocationDetail } from "../models/Location";
import React from "react";
import img from "../assets/znacznik.png";
import { LocationContext } from "../contexts/LocationContext";
import { Cluster } from '@react-google-maps/marker-clusterer';
import { Link } from "react-router-dom";

const libraries: Libraries = ['places'];

const mapStyles = [
    {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
    },
];

const options = {
    streetViewControl: false,
    fullscreenControl: true,
    mapTypeControl: false,
    styles: mapStyles
};


interface IMapGoogle {
    width?: string;
    height?: string;
    center?: { lat: number, lng: number }
    zoom?: number;
    className?: string;
    locationsFiltered: LocationDetail[];
}
const maxZoom = 20;
export const MapGoogle: React.FC<IMapGoogle> = ({ width = "100%", height = "100%", center = { lat: 52.225854, lng: 20.01 }, zoom = 6, className, locationsFiltered = [] }: IMapGoogle) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDvoZrNBcizRP1OcXi5HLGVDdHjWKhWidA',
        libraries: libraries,
    });
    const [locations, setLocations] = useState<LocationDetail[]>(locationsFiltered);

    const [selectedLocation, setSelectedLocation] = useState<LocationDetail | null>(null);
    const mapContainerStyle: React.CSSProperties = {
        width: width,
        height: height,
        borderRadius: "29px"
    };
    const [nextPosition, setNextPosition] = useState<{ lat: number, lng: number }>(center);


    var getGoogleClusterInlineSvg = (color: string) => {
        var encoded = window.btoa(`<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">'
          <circle cx="120" cy="120" opacity=".6" r="70" />
          <circle cx="120" cy="120" opacity=".4" r="95" />
          <circle cx="120" cy="120" opacity=".2" r="120" />
        </svg>`)
        return ('data:image/svg+xml;base64,' + encoded);
    };

    const clusterStyles = [
        {
            textColor: 'white',
            url: getGoogleClusterInlineSvg("#FF9100"),
            height: 40,
            width: 40
        },
        {
            textColor: 'white',
            url: getGoogleClusterInlineSvg("#008CFF"),
            height: 50,
            width: 50
        },
        {
            textColor: 'white',
            url: getGoogleClusterInlineSvg("#FF0000"),
            height: 60,
            width: 60
        },
        {
            textColor: 'white',
            url: getGoogleClusterInlineSvg("#FF00ED"),
            height: 70,
            width: 80,


        }
    ];

    const [selectedCluster, setSelectedCluster] = useState<LocationDetail[] | null>();
    const mapRef = useRef<google.maps.Map>();

    const onMarkerClustererClick = (cluster: Cluster) => {
        if (mapRef.current) {
            const mapZoom = mapRef.current.getZoom();
            if (mapZoom === maxZoom) {
                var mainLocation: LocationDetail | undefined = locations.find(loc => loc.id == cluster.markers[0].getTitle());
                if (mainLocation) {
                    var clusterLocations: LocationDetail[] | undefined = locations.filter((loc: LocationDetail) =>
                        loc.loclon == mainLocation?.loclon && loc.loclat == mainLocation.loclat
                    );
                    setSelectedCluster(clusterLocations)
                }
            }
        }
    };

    const handleMarkerClick = (location: LocationDetail) => {
        var mainLocation: LocationDetail | undefined = location;
        if (mainLocation) {
            var clusterLocations: LocationDetail[] | undefined = locations.filter((loc: LocationDetail) =>
                loc.loclon == mainLocation?.loclon && loc.loclat == mainLocation.loclat
            );
            if (clusterLocations.length > 1) {
                setSelectedCluster(clusterLocations)

            } else {
                setSelectedLocation(location);
            }
        };


    };

    return (
        <div className={className}>
            {isLoaded && locations && (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={nextPosition}
                    zoom={zoom}
                    options={options}
                    onLoad={(map: any) => mapRef.current = map}
                    onZoomChanged={() => {
                        if (mapRef.current && mapRef.current.getZoom() === maxZoom) {
                            setSelectedCluster(null);
                        }
                    }}
                >
                    <MarkerClusterer
                        averageCenter={false}
                        gridSize={70}
                        maxZoom={maxZoom}
                        minimumClusterSize={2}
                        zoomOnClick
                        styles={clusterStyles}
                        onClick={(cluster: Cluster) => { onMarkerClustererClick(cluster) }}
                    >
                        {(clusterer) =>
                            <React.Fragment>
                                {locations.map((location, index) => {
                                    const loc = { lat: Number(location.loclat), lng: Number(location.loclon) };
                                    return (
                                        loc.lat !== 0 && (
                                            <Marker
                                                key={index}
                                                position={loc}
                                                clusterer={clusterer}
                                                onClick={() => handleMarkerClick(location)}
                                                title={"Przykład szczegółów miejsca"}
                                                icon={{
                                                    url: img,
                                                    scaledSize: new window.google.maps.Size(30, 40),
                                                }}
                                            />
                                        )
                                    );
                                })
                                }
                            </React.Fragment>
                        }
                    </MarkerClusterer>
                    {selectedLocation && (
                        <InfoWindow
                            position={{ lat: Number(selectedLocation.loclat), lng: Number(selectedLocation.loclon) }}
                            onCloseClick={() => setSelectedLocation(null)}
                            options={{
                                pixelOffset: new window.google.maps.Size(0, -40)
                            }}

                        >
                            <div className="w-[200px]">
                                <h2 className="pb-2">{selectedLocation.street + " " + selectedLocation.number + ", " + selectedLocation.city}</h2>
                                <div>
                                    <p>
                                        <span className="font-bold">Nazwa: </span>{selectedLocation.name}
                                    </p>
                                    <p>
                                        <span className="font-bold">Typ: </span>{selectedLocation.subname}
                                    </p>
                                </div>
                                <div className="mt-2 text-blue-700">
                                    <Link to={`/lokalizacje/${selectedLocation.id}`} aria-label={`Przejdź do szczegółów znacznika`}>Przejdź do szczegółów znacznika</Link>
                                </div>
                            </div>
                        </InfoWindow>
                    )}
                    {selectedCluster && selectedCluster?.length > 0 && (
                        <InfoWindow
                            position={{ lat: Number(selectedCluster[0].loclat), lng: Number(selectedCluster[0].loclon) }}
                            onCloseClick={() => setSelectedCluster([])}
                            options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                        >
                            <div className="w-[200px] h-[300px]">
                                <h2 className="pb-2 font-bold">Lokacje w klastrze</h2>
                                <div className="flex flex-col gap-2">
                                    {selectedCluster.map((location, index) => (
                                        <div key={location.id} className="flex flex-col">
                                            <p>
                                                <span className="font-bold">Numer: </span>{index + " "}
                                            </p>
                                            <p>
                                                <span className="font-bold">Nazwa: </span>{location.name}
                                            </p>
                                            <p className="border-b border-gray-400 pb-2">
                                                <span className="font-bold">Typ: </span>{location.subname}
                                                <div className="mt-2 text-blue-700">
                                                    <Link to={`/lokalizacje/${location.id}`} aria-label={`Przejdź do szczegółów znacznika`}>Przejdź do szczegółów znacznika</Link>
                                                </div>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>
            )}
        </div>
    );
}